<template>
  <div class="courseRouter">
    <!-- 合作企业占位路由 -->
    <router-view />
  </div>
</template>

<script>
export default {
  name: "FirmRouter",
  props: {},
  components: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {},
  created() {},
  mounted() {}
};
</script>

<style scoped lang="scss">
.courseRouter {
  width: 100%;
  height: 100%;
}
</style>
